import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { environment } from '../../../environments/environment';

const AdultAge = environment.adultAge;

export const adult = (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    let dob: Date;

    if (typeof value === 'string') {
      dob = new Date(value);
    } else {
      dob = value;
    }

    const today = new Date();
    const years = today.getFullYear() - dob.getFullYear();
    let isAdult = false;

    if (years > AdultAge) {
      isAdult = true;
    } else if (years === AdultAge) {
      const monthDiff = today.getMonth() - dob.getMonth();

      if (monthDiff > 0) {
        isAdult = true;
      } else if (monthDiff === 0) {
        isAdult = today.getDate() - dob.getDate() >= 0;
      }
    }

    return !isAdult ? { adult: false } : null;
  };
